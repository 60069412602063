import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/header";
import layoutLess from "./layout.less";

import { useState, useEffect, memo } from "react";

import ajax from "./assets/js/ajax";
import { Badge } from "antd";
import DgCopyright from "./components/dg-copyright";

// type MenuItem = Required<MenuProps>["items"][number];

// function getItem(
//   label: React.ReactNode,
//   key: React.Key,
//   icon?: React.ReactNode,
//   children?: MenuItem[],
//   type?: "group"
// ): MenuItem {
//   return {
//     key,
//     icon,
//     children,
//     label,
//     type,
//   } as MenuItem;
// }

export default memo(() => {
  const navigate = useNavigate();
  const location = useLocation();

  let pathname = location.pathname;
  let [info, setInfo] = useState({
    realName: "",
  });

  const [invoice, setInvoice] = useState<number>(0);
  const [courtLawsuitOrder, setCourtLawsuitOrder] = useState<number>(0);
  const [engineeringContractOrder, setEngineeringContractOrder] =
    useState<number>(0);
  const [engineeringPeasantOrder, setEngineeringPeasantOrder] =
    useState<number>(0);
  const [engineeringQualityOrder, setEngineeringQualityOrder] =
    useState<number>(0);
  const [purchaseContractOrder, setPurchaseContractOrder] = useState<number>(0);

  const [activeMenus, setActiveMenus] = useState<string[]>([]);

  /**
   * @author 伟健 / 张
   * @name 当前用户信息
   * @param
   * @return
   */
  let getCurrentInfo = () => {
    ajax.post({
      url: "/openPlatform/user/current",
      data: {},
      self: this,
      success: (res) => {
        localStorage.setItem("current", JSON.stringify(res));

        setInfo(res.user || {});
      },
      fail: () => {
        navigate("/");
      },
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 发票订单数量
   * @return {*}
   */
  const getInvoiceBadges = () => {
    ajax.post({
      url: "/openPlatform/quantityCorner/invoiceCorner",
      data: {},
      self: this,
      success: (res) => {
        setInvoice(res.invoice || 0);
      },
      fail: () => {},
    });
  };

  const clickMenus = (value: string) => {
    if (activeMenus.includes(value)) {
      setActiveMenus(activeMenus.filter((el) => el != value));
      return;
    }
    setActiveMenus([...activeMenus, value]);
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 保函订单数量
   * @return {*}
   */
  const getBaohanBadges = () => {
    ajax.post({
      url: "/openPlatform/quantityCorner/orderCorner",
      data: {},
      self: this,
      success: (res) => {
        setCourtLawsuitOrder(res.courtLawsuitOrder || 0);
        setEngineeringContractOrder(res.engineeringContractOrder || 0);
        setEngineeringPeasantOrder(res.engineeringPeasantOrder || 0);
        setEngineeringQualityOrder(res.engineeringQualityOrder || 0);
        setPurchaseContractOrder(res.purchaseContractOrder || 0);
      },
      fail: () => {},
    });
  };

  let menuLi = () => {
    let menus = [
      {
        name: "产品中心",
        icon: "&#xe61b;",
        path: "",
        childer: [
          {
            name: "产品中心",
            path: "/admin/product",
          },
          {
            name: "密钥管理",
            path: "/admin/secretkey",
          },
        ],
      },
      {
        name: "我的产品",
        icon: "&#xe9be;",
        path: "",
        childer: [
          {
            name: "我的产品",
            path: "/admin/myProducts",
          },
          {
            name: "产品账单",
            path: "/admin/productBill",
          },
        ],
      },
      {
        name: "费用管理",
        icon: "&#xe608;",
        path: "",
        childer: [
          {
            name: "我的订单",
            path: "/admin/order",
          },
          {
            name: "我的发票",
            path: "/admin/invoice",
          },
          {
            name: "余额",
            path: "/admin/recharge",
          },
        ],
      },
      {
        name: "保函管理",
        icon: "&#xe9be;",
        path: "",
        childer: [
          {
            name: "工程合同履约保函",
            path: "/admin/engContract",
          },
          {
            name: "工程质量保函",
            path: "/admin/engQuality",
          },
          {
            name: "农民工工资支付保函",
            path: "/admin/nmgPay",
          },
          {
            name: "政采合同履约保函",
            path: "/admin/govContract",
          },
          {
            name: "法院诉讼保函",
            path: "/admin/procceedings",
          },
          {
            name: "安责险保函",
            path: "/admin/safety",
          },
          {
            name: "保函申请发票",
            path: "/admin/invoices",
          },
        ],
      },
      {
        name: "事故预防服务",
        icon: "&#xe9be;",
        path: "",
        childer: [
          {
            name: "事故预防服务订单",
            path: "/admin/accidentOrder",
          },
          {
            name: "投诉列表",
            path: "/admin/accident/complaintList",
          },
        ],
      },
      {
        name: "安全中心",
        icon: "&#xe619;",
        path: "",
        childer: [
          {
            name: "账户信息",
            path: "/admin",
          },
          {
            name: "企业认证",
            path: "/admin/company",
          },
        ],
      },
    ];

    const badgesPath = [
      "/admin/engContract",
      "/admin/engQuality",
      "/admin/nmgPay",
      "/admin/govContract",
      "/admin/procceedings",
      "/admin/invoices",
    ];

    return menus.map((el, index) => {
      return (
        <li key={index} className={`${layoutLess.meunTop} `}>
          <p className="flex " onClick={() => clickMenus(el.name)}>
            <i
              className="icon t-[20]"
              dangerouslySetInnerHTML={{
                __html: el.icon,
              }}
            ></i>
            <span className="t-[16] pl-[10]">{el.name}</span>{" "}
            <i className="icon  ml-[auto] mr-[0]">&#xe646;</i>
          </p>
          <ul
            className={`${layoutLess.normalMenu} ${
              activeMenus.includes(el.name)
                ? layoutLess.activeMenu
                : layoutLess.menu
            }`}
          >
            {el.childer.map((item, ind) => {
              if (badgesPath.includes(item.path)) {
                return (
                  <li
                    key={ind}
                    className={
                      pathname === item.path
                        ? layoutLess.active
                        : layoutLess.menuBox
                    }
                  >
                    <Link key={ind} to={{ pathname: item.path }}>
                      {item.name}
                    </Link>
                    {item.path === "/admin/invoices" && invoice > 0 && (
                      <Badge
                        count={invoice}
                        style={{ backgroundColor: "#FFFFFF", color: "#407BF5" }}
                      ></Badge>
                    )}
                    {item.path === "/admin/procceedings" &&
                      courtLawsuitOrder > 0 && (
                        <Badge
                          count={courtLawsuitOrder}
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#407BF5",
                          }}
                        ></Badge>
                      )}
                    {item.path === "/admin/engContract" &&
                      engineeringContractOrder > 0 && (
                        <Badge
                          count={engineeringContractOrder}
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#407BF5",
                          }}
                        ></Badge>
                      )}
                    {item.path === "/admin/engQuality" &&
                      engineeringQualityOrder > 0 && (
                        <Badge
                          count={engineeringQualityOrder}
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#407BF5",
                          }}
                        ></Badge>
                      )}
                    {item.path === "/admin/nmgPay" &&
                      engineeringPeasantOrder > 0 && (
                        <Badge
                          count={engineeringPeasantOrder}
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#407BF5",
                          }}
                        ></Badge>
                      )}
                    {item.path === "/admin/govContract" &&
                      purchaseContractOrder > 0 && (
                        <Badge
                          count={purchaseContractOrder}
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#407BF5",
                          }}
                        ></Badge>
                      )}
                  </li>
                );
              } else {
                return (
                  <li
                    key={ind}
                    className={
                      pathname === item.path
                        ? layoutLess.active
                        : layoutLess.menuBox
                    }
                  >
                    <Link key={ind} to={{ pathname: item.path }}>
                      {item.name}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </li>
      );
    });
  };

  const initActiveMenu = () => {
    if (["/admin/company", "/admin"].includes(location.pathname)) {
      setActiveMenus([...activeMenus, "安全中心"]);
    }
    if (
      [
        "/admin/engContract",
        "/admin/engQuality",
        "/admin/nmgPay",
        "/admin/govContract",
        "/admin/procceedings",
        "/admin/invoices",
      ].includes(location.pathname)
    ) {
      // clickMenus("");
      setActiveMenus([...activeMenus, "保函管理"]);
    }
    if (
      ["/admin/order", "/admin/invoice", "/admin/recharge"].includes(
        location.pathname
      )
    ) {
      // clickMenus("");
      setActiveMenus([...activeMenus, "费用管理"]);
    }
    if (
      ["/admin/myProducts", "/admin/productBill"].includes(location.pathname)
    ) {
      // clickMenus("");
      setActiveMenus([...activeMenus, "我的产品"]);
    }
    if (["/admin/product", "/admin/secretkey"].includes(location.pathname)) {
      // clickMenus("");
      setActiveMenus([...activeMenus, "产品中心"]);
    }
  };

  // const

  // const items: MenuProps["items"] = [
  //   getItem(
  //     "产品中心",
  //     "sub1",
  //     <i className="icon t-[20]">&#xe61b;</i>,
  //     [getItem("产品中心", "/admin/product"), getItem("秘钥管理", "/admin/secretkey")]
  //   ),
  //   getItem(
  //     "我的产品",
  //     "sub2",
  //     <i className="icon t-[20]">&#xe9be;</i>,
  //     [getItem("我的产品", "/admin/myProducts"), getItem("产品账单", "/admin/productBill")]
  //   ),
  //   getItem(
  //     "费用管理",
  //     "sub2",
  //     <i className="icon t-[20]">&#xe9be;</i>,
  //     [getItem("我的产品", "/admin/myProducts"), getItem("产品账单", "/admin/productBill")]
  //   ),
  // ];

  useEffect(() => {
    getCurrentInfo();
    getInvoiceBadges();
    getBaohanBadges();
  }, []);

  useEffect(() => {
    getInvoiceBadges();
    getBaohanBadges();
    initActiveMenu();
  }, [location]);

  return (
    <div className={layoutLess.wrap}>
      <Header info={info}></Header>
      <div className="w-[1200] mx-[auto] pt-[8] flex justify-between pb-[30]">
        <div className={`${layoutLess.sider} pt-[10]`}>
          <ul>{menuLi()}</ul>
        </div>
        <div className={layoutLess.content}>
          <Outlet></Outlet>
        </div>
      </div>

      <div className={layoutLess.contact_wrap}>
        <div className={layoutLess.contact}>
          <i className="icon aui-color-ff t-[30]">&#xe601;</i>
        </div>
        {/* <div className={layoutLess.contact_info}>
          <p className="t-[16]">客服热线：400-816-1767</p>
          <p className="pt-[6] t-[16]">
            服务时间：工作日9:00-18:00
          </p>
        </div> */}
      </div>
      <DgCopyright paddingTop="0px" paddingBottom="30px" />
    </div>
  );
});
