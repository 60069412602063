// import Dgupload from "../../components/dg-upload";
import {
  Button,
  Collapse,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Radio,
  Table,
  Tabs,
  message,
} from "antd";
import Dgupload from "../../components/dg-upload";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  CaretRightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import tagPng from "../../assets/img/tag.png";
import serviceLess from "./css/service.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import { downloadFile } from "../../assets/js/util";

const { confirm } = Modal;
const { Panel } = Collapse;

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
    activeTab: "1";
  };

  const [data, setData] = useState<any>({});
  const [missIdentificationRecord, setMissIdentificationRecord] = useState<
    any[]
  >([]);

  // 复测结果
  const [retestVisible, setRetestVisible] = useState<boolean>(false);
  const [retestForm] = Form.useForm();
  const [retestReport, setRetestReport] = useState<string>("");

  // z整改
  const [rectifyVisible, setRectifyVisible] = useState<boolean>(false);
  const [rectifyForm] = Form.useForm();
  // const [rectifyReport, setRectifyReport] = useState<string>("");

  // 隐患
  const [hiddenVisible, setHiddenVisible] = useState<boolean>(false);
  const [hiddenForm] = Form.useForm();
  const [examiningReport, setExaminingReport] = useState<string>("");

  // 总结
  const [summaryVisible, setSummaryVisible] = useState<boolean>(false);
  const [summaryForm] = Form.useForm();

  // 风险评估报告
  const [riskVisible, setRiskVisible] = useState<boolean>(false);
  const [riskForm] = Form.useForm();
  const [riskFileReport, setRiskFileReport] = useState<string>("");

  // 其他文件
  const [otherVisible, setOtherVisible] = useState<boolean>(false);
  const [otherForm] = Form.useForm();
  const [otherFileReport, setOtherFileReport] = useState<string>("");

  const [serviceVisible, setServiceVisible] = useState<boolean>(false);
  const [id, setId] = useState<number | null>();
  const [missIdentificationRecordId, setMissIdentificationRecordId] = useState<
    number | null
  >();
  const [version, setVersion] = useState<number | null>();

  // 文件列表开始
  interface DataType2 {
    count: number;
    description: string;
    serviceContent: string;
    serviceContentId: number;
  }

  // 文件列表开始
  interface DataType {
    key: string;
    id: number;
    name: string;
    description: string;
    enable: boolean;
    gmtModified: string;
    path: string;
  }

  const editFiles = (item: any) => {
    setId(item.id);
    setVersion(item.version);
    if (item.code == "RISK") {
      setRiskFileReport(item.path);
      riskForm.setFieldsValue({
        riskFileReport: item.path,
        name: item.name,
        description: item.description,
        enable: item.enable,
      });
      setRiskVisible(true);
    }
    if (item.code == "OTHER") {
      setOtherFileReport(item.path);
      otherForm.setFieldsValue({
        otherFileReport: item.path,
        name: item.name,
        description: item.description,
        enable: item.enable,
      });
      setOtherVisible(true);
    }
  };

  const conFirmDel = (id: number) => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrderAttachment/deleteRiskAndOther",
      data: { attachmentId: id },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          message.success("删除成功");
          getDetail();
        }
      },
      fail: () => {},
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "报告名称",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "说明",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "enable",
      key: "enable",
      align: "center",
      width: 60,
      render: (_, record) => <span>{record.enable ? "启用" : "禁用"}</span>,
    },
    {
      title: "更新时间",
      dataIndex: "gmtModified",
      key: "gmtModified",
      align: "center",
      width: 160,
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      width: 120,
      render: (text, record) => {
        return (
          <div className="flex justify-center">
            <span className="aui-link">
              <a href={record.path} target="_blank">
                下载
              </a>
            </span>
            {data.type == 1 && (
              <div>
                <span
                  className="aui-link pl-[4]"
                  onClick={() => editFiles(record)}
                >
                  编辑
                </span>
                <span className="aui-link pl-[4]">
                  <Popconfirm
                    title="删除后内容不可恢复,是否继续?"
                    onConfirm={() => conFirmDel(record.id)}
                    okText="确定"
                    cancelText="取消"
                  >
                    <a href="#">删除</a>
                  </Popconfirm>
                </span>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const [tableData, setTableData] = useState<DataType[]>([]);
  const [tableData2, setTableData2] = useState<DataType[]>([]);
  const [serviceContentData, setServiceContentData] = useState<DataType2[]>([]);

  const columns2: ColumnsType<DataType> = [
    {
      title: "报告名称",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "说明",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "状态",
      dataIndex: "enable",
      key: "enable",
      align: "center",
      render: (_, record) => <span>{record.enable ? "启用" : "禁用"}</span>,
    },
    {
      title: "更新时间",
      dataIndex: "gmtModified",
      key: "gmtModified",
      align: "center",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "oper",
      align: "center",
      render: (text, record) => {
        return (
          <div className="flex justify-center">
            <span className="aui-link">
              <a href={record.path} target="_blank">
                下载
              </a>
            </span>
            {data.type == 1 && (
              <div>
                <span
                  className="aui-link pl-[4]"
                  onClick={() => editFiles(record)}
                >
                  编辑
                </span>
                <span className="aui-link pl-[4]">
                  <Popconfirm
                    title="删除后内容不可恢复,是否继续?"
                    onConfirm={() => conFirmDel(record.id)}
                    okText="确定"
                    cancelText="取消"
                  >
                    <a href="#">删除</a>
                  </Popconfirm>
                </span>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const columns3: ColumnsType<DataType2> = [
    {
      title: "服务内容名称",
      dataIndex: "serviceContent",
      key: "serviceContent",
      align: "center",
    },
    {
      title: "说明",
      dataIndex: "description",
      key: "description",
      align: "center",
    },
    {
      title: "次数(次)",
      dataIndex: "count",
      key: "count",
      align: "center",
    },
  ];
  // 文件列表结束

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/detail",
      data: params,
      self: this,
      success: (res) => {
        setData(res || {});
        if (
          res.missIdentificationRecord &&
          res.missIdentificationRecord.length > 0
        ) {
          setMissIdentificationRecord(res.missIdentificationRecord);
        }
        if (res.files["OTHER"] && res.files["OTHER"].length > 0) {
          setTableData2(res.files["OTHER"]);
        }
        if (res.files["RISK"] && res.files["RISK"].length > 0) {
          setTableData(res.files["RISK"]);
        }
        if (res.serviceContent && res.serviceContent.length > 0) {
          setServiceContentData(res.serviceContent);
        }
      },
      fail: () => {},
    });
  };

  // 提交复测结果
  const handleSubmitRetest = (values: any) => {
    confirm({
      title: "提示",
      icon: <ExclamationCircleOutlined />,
      content: "提交后不可更改，请检查无误后提交。是否继续提交？",
      onOk() {
        ajax.post({
          url: "/openPlatform/missIdentificationRecord/submitRetestResult",
          data: {
            ...values,
            recordId: missIdentificationRecordId,
            retestReport,
          },
          self: this,
          success: (res) => {
            if (res.code == 200) {
              message.success("保存成功");
              getDetail();
              setRetestVisible(false);
            }
          },
          fail: () => {},
        });
      },
      onCancel() {},
    });
  };

  // 提交更改检测报告
  const handleSubmitRectify = (values: any) => {
    ajax.post({
      url: "/openPlatform/missIdentificationRecord/updateExaminingReport",
      data: { recordId: missIdentificationRecordId, examiningReport },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          message.success("保存成功");
          getDetail();
          setRectifyVisible(false);
          setExaminingReport("");
        }
      },
      fail: () => {},
    });
  };

  // 提交隐患排查
  const handleSubmitHidden = (values: any) => {
    ajax.post({
      url: "/openPlatform/missIdentificationRecord/create",
      data: { ...values, tradeNo: params.tradeNo, examiningReport },
      self: this,
      success: (res) => {
        console.log(res);
        if (res.code == 200) {
          message.success("保存成功");
          getDetail();
          setHiddenVisible(false);
          setExaminingReport("");
        }
      },
      fail: () => {},
    });
  };

  // 提交编辑总结
  const handleSubmitSummary = (values: any) => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrder/editSummarize",
      data: { ...values, tradeNo: params.tradeNo },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          message.success("保存成功");
          getDetail();
          setSummaryVisible(false);
        }
      },
      fail: () => {},
    });
  };

  const openFiles = (type: string) => {
    setId(null);
    setVersion(null);
    if (type == "OTHER") {
      setOtherVisible(true);
    }
    if (type == "RISK") {
      setRiskVisible(true);
    }
  };

  const handleSubmitOtherRisk = (values: any, type: string) => {
    ajax.post({
      url: "/openPlatform/accidentPreventionOrderAttachment/createOrEditRiskAndOther",
      data: {
        ...values,
        code: type,
        path: type == "OTHER" ? otherFileReport : riskFileReport,
        tradeNo: params.tradeNo,
        id,
        version,
      },
      self: this,
      success: (res) => {
        if (res.code == 200) {
          message.success("保存成功");
          if (type == "OTHER") {
            otherForm.resetFields();
            setOtherFileReport("");
            setOtherVisible(false);
          }
          if (type == "RISK") {
            riskForm.resetFields();
            setRiskFileReport("");
            setRiskVisible(false);
          }
          getDetail();
        }
      },
      fail: () => {},
    });
  };

  // 打开弹窗
  const openVisible = (id: number, t: string) => {
    setMissIdentificationRecordId(id);
    if (t == "Retest") {
      setRetestVisible(true);
    } else {
      setRectifyVisible(true);
    }
  };

  useEffect(() => {
    retestForm.setFieldsValue({
      needRectify: true,
    });
    hiddenForm.setFieldsValue({
      needRectify: true,
    });
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          服务项目
        </span>
      </div>
      <div className={serviceLess.dg__card}>
        <p className={serviceLess.title}>基础信息</p>
        {data.type == 0 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="项目名称">{data.projectName || "-"}</Dglistitem>
            <Dglistitem label="施工企业名称">
              {data.constructionUnitName || "-"}
            </Dglistitem>
            <Dglistitem label="服务名称">{data.name || "-"}</Dglistitem>
            <Dglistitem label="安评公司名称">
              {data.safetyEnterpriseName || "-"}
            </Dglistitem>
            <Dglistitem label="服务内容">
              <span
                className="aui-link"
                onClick={() => setServiceVisible(true)}
              >
                查看详情
              </span>
            </Dglistitem>
            <Dglistitem label="服务方案">
              <div>
                {data.plan ? (
                  <span>
                    <a href={data.plan} target="_blank">
                      查看
                    </a>

                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.plan, "服务方案")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem>
            <Dglistitem label="服务合同">
              <div>
                {data.contract ? (
                  <span>
                    <a href={data.contract} target="_blank">
                      查看
                    </a>

                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.contract, "服务合同")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem>
            <Dglistitem label="服务开始，结束时间">
              {data.startTime && data.insureEffectTimeEnd
                ? `${data.startTime}~${data.insureEffectTimeEnd}`
                : "-"}
            </Dglistitem>
            <Dglistitem label="安责险保函编号">
              {data.insureOrderNo || "-"}
            </Dglistitem>
            <Dglistitem label="保函期限">
              {data.insureEffectTimeStart && data.insureEffectTimeEnd
                ? `${data.insureEffectTimeStart}~${data.insureEffectTimeEnd}`
                : "-"}
            </Dglistitem>
          </Dglist>
        )}
        {data.type == 1 && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="项目名称">{data.projectName || "-"}</Dglistitem>
            <Dglistitem label="施工企业名称">
              {data.constructionUnitName || "-"}
            </Dglistitem>
            <Dglistitem label="服务名称">{data.name || "-"}</Dglistitem>
            <Dglistitem label="服务开始，结束时间">
              {data.startTime && data.insureEffectTimeEnd
                ? `${data.startTime}~${data.insureEffectTimeEnd}`
                : "-"}
            </Dglistitem>
            <Dglistitem label="服务方案">
              <div>
                {data.plan ? (
                  <span>
                    <a href={data.plan} target="_blank">
                      查看
                    </a>

                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.plan, "服务方案")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem>
            <Dglistitem label="服务内容">
              <span
                className="aui-link"
                onClick={() => setServiceVisible(true)}
              >
                查看详情
              </span>
            </Dglistitem>
            {/* <Dglistitem label="服务合同">
              <div>
                {data.contract ? (
                  <span>
                    <a href={data.contract} target="_blank">
                      查看
                    </a>

                    <span
                      className="aui-link pl-[6]"
                      onClick={() => downloadFile(data.contract, "服务合同")}
                    >
                      下载
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </Dglistitem> */}
            <Dglistitem label="安责险保函编号">
              {data.insureOrderNo || "-"}
            </Dglistitem>
            <Dglistitem label="保函期限">
              {data.insureEffectTimeStart && data.insureEffectTimeEnd
                ? `${data.insureEffectTimeStart}~${data.insureEffectTimeEnd}`
                : "-"}
            </Dglistitem>
            <Dglistitem label="服务形式">{data.typeDesc || "-"}</Dglistitem>
          </Dglist>
        )}
      </div>
      <div className={serviceLess.dg__card}>
        <p className={serviceLess.title}>服务项目</p>
        <Tabs defaultActiveKey={params.activeTab}>
          <Tabs.TabPane tab="隐患排查" key="1">
            <div className={serviceLess.tab1title}>
              {data.summarize && (
                <div className={`${serviceLess.tab1title_tag} mr-[10]`}>
                  <img src={tagPng} alt="" />
                  <span>总体情况：{data.summarize}</span>
                </div>
              )}
              {data.type == 1 && (
                <div
                  className={serviceLess.tab1title_opers}
                  style={{ marginLeft: "auto" }}
                >
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setHiddenVisible(true)}
                  >
                    新增隐患排查
                  </Button>
                  {missIdentificationRecord.length > 0 && (
                    <Button
                      ghost
                      type="primary"
                      style={{ backgroundColor: "#E5EDFF" }}
                      onClick={() => setSummaryVisible(true)}
                    >
                      添加/编辑总结情况
                    </Button>
                  )}
                </div>
              )}
            </div>
            {missIdentificationRecord.length > 0 && (
              <Collapse
                ghost
                accordion
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    rotate={isActive ? 90 : 0}
                    style={{ color: "#B2B2B2" }}
                  />
                )}
                expandIconPosition="end"
              >
                {missIdentificationRecord.map((item, index) => {
                  const { recordRelationVo = {} } = item;
                  return (
                    <Panel
                      header={
                        <div className={serviceLess.panelHeader}>
                          <p>服务记录：第{item.count}次</p>
                          {data.type == 1 && (
                            <Button
                              ghost
                              type="primary"
                              disabled={item.status != 1}
                              onClick={() => openVisible(item.id, "Retest")}
                            >
                              提交复测结果
                            </Button>
                          )}
                        </div>
                      }
                      key={item.id}
                    >
                      <div className={serviceLess.panel_card}>
                        <div className={serviceLess.panel_card_title}>
                          <span>检测结果</span>
                        </div>
                        {/* 第三方机构 */}
                        {data.type == 0 && (
                          <Dglist column={2} labelWidth="200px">
                            <Dglistitem label="检测报告">
                              <div>
                                {item.examiningReport ? (
                                  <span
                                    className="aui-link pl-[6]"
                                    onClick={() =>
                                      downloadFile(
                                        item.examiningReport,
                                        "检测报告"
                                      )
                                    }
                                  >
                                    下载
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </Dglistitem>
                            <Dglistitem label="检测说明">
                              {item.examiningDesc || "-"}
                            </Dglistitem>
                            <Dglistitem label="重大风险数(个)">
                              {item.majorRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="较大风险数(个)">
                              {item.higherRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="一般风险数(个)">
                              {item.normalRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="低风险数(个)">
                              {item.lowRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="风险总数(个)">
                              {item.totalRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="更新时间">
                              {item.examiningUpdateTime || "-"}
                            </Dglistitem>
                          </Dglist>
                        )}
                        {/* 内部机构 */}
                        {data.type == 1 && (
                          <Dglist column={2} labelWidth="200px">
                            <Dglistitem label="检测报告">
                              <div className={serviceLess.changeBtn}>
                                <div>
                                  {item.examiningReport ? (
                                    <span
                                      className="aui-link pl-[6]"
                                      onClick={() =>
                                        downloadFile(
                                          item.examiningReport,
                                          "检测报告"
                                        )
                                      }
                                    >
                                      下载
                                    </span>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                                {!item.submitRectify && (
                                  <Button
                                    ghost
                                    danger
                                    size="small"
                                    type="primary"
                                    onClick={() =>
                                      openVisible(item.id, "Rectify")
                                    }
                                  >
                                    更改
                                  </Button>
                                )}
                              </div>
                            </Dglistitem>
                            <Dglistitem label="检测说明">
                              {item.examiningDesc || "-"}
                            </Dglistitem>
                            <Dglistitem label="重大风险数(个)">
                              {item.majorRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="较大风险数(个)">
                              {item.higherRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="一般风险数(个)">
                              {item.normalRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="低风险数(个)">
                              {item.lowRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="风险总数(个)">
                              {item.totalRiskNum || "-"}
                            </Dglistitem>
                            <Dglistitem label="更新时间">
                              {item.examiningUpdateTime || "-"}
                            </Dglistitem>
                          </Dglist>
                        )}
                      </div>
                      <div className={serviceLess.panel_card}>
                        <div className={serviceLess.panel_card_title}>
                          <span>整改结果</span>
                        </div>
                        <Dglist column={2} labelWidth="200px">
                          <Dglistitem label="整改报告">
                            <div>
                              {recordRelationVo.rectifyReport ? (
                                <span
                                  className="aui-link pl-[6]"
                                  onClick={() =>
                                    downloadFile(recordRelationVo.rectifyReport, "整改报告")
                                  }
                                >
                                  下载
                                </span>
                              ) : (
                                "-"
                              )}
                            </div>
                          </Dglistitem>
                          <Dglistitem label="整改说明">
                            {recordRelationVo.rectifyDesc || "-"}
                          </Dglistitem>
                          <Dglistitem label="已整改风险数(个)">
                            {recordRelationVo.rectifyRiskNum || "-"}
                          </Dglistitem>
                          <Dglistitem label="更新时间">
                            {recordRelationVo.rectifyUpdateTime || "-"}
                          </Dglistitem>
                        </Dglist>
                      </div>
                      <div className={serviceLess.panel_card}>
                        <div className={serviceLess.panel_card_title}>
                          <span>复测结果</span>
                        </div>
                        <Dglist column={2} labelWidth="200px">
                          <Dglistitem label="复测报告">
                            <div>
                              {recordRelationVo.retestReport ? (
                                <span
                                  className="aui-link pl-[6]"
                                  onClick={() =>
                                    downloadFile(recordRelationVo.retestReport, "复测报告")
                                  }
                                >
                                  下载
                                </span>
                              ) : (
                                "-"
                              )}
                            </div>
                          </Dglistitem>
                          <Dglistitem label="复测说明">
                            {recordRelationVo.retestDesc || "-"}
                          </Dglistitem>
                          <Dglistitem label="整改达标数(个)">
                            {recordRelationVo.retestSuccessNum || "-"}
                          </Dglistitem>
                          <Dglistitem label="整改未达标数(个)">
                            {recordRelationVo.retestFailNum || "-"}
                          </Dglistitem>
                          <Dglistitem label="整改达标率">
                            {recordRelationVo.retestSuccessRate || "-"}
                          </Dglistitem>
                          <Dglistitem label="复测次数(次)">
                            {recordRelationVo.retestCount || "-"}
                          </Dglistitem>
                          <Dglistitem label="更新时间">
                            {recordRelationVo.retestUpdateTime || "-"}
                          </Dglistitem>
                        </Dglist>
                      </div>
                    </Panel>
                  );
                })}
              </Collapse>
            )}
            {missIdentificationRecord.length == 0 && (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="风险评估报告" key="2">
            {data.type == 1 && (
              <div className={serviceLess.tableOper}>
                <Button type="primary" onClick={() => openFiles("RISK")}>
                  添加风险评估报告
                </Button>
              </div>
            )}
            <Table
              pagination={false}
              columns={columns}
              dataSource={tableData}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="其他文件" key="3">
            {data.type == 1 && (
              <div className={serviceLess.tableOper}>
                <Button type="primary" onClick={() => openFiles("OTHER")}>
                  添加其他文件
                </Button>
              </div>
            )}
            <Table
              pagination={false}
              columns={columns2}
              dataSource={tableData2}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/accidentOrder");
          }}
        >
          返回
        </Button>
      </div>

      {/* 提交复测结果弹窗 */}
      <Modal
        title="提交复测结果"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setRetestVisible(false)}
        open={retestVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={retestForm}
          onFinish={handleSubmitRetest}
        >
          <Form.Item
            label="整改达标数(个)"
            name="retestSuccessNum"
            rules={[{ required: true, message: "请输入整改达标数" }]}
          >
            <InputNumber
              min={1}
              precision={0}
              style={{ width: "100%" }}
              placeholder="请输入整改达标数"
            />
          </Form.Item>
          <Form.Item
            label="整改未达标数(个)"
            name="retestFailNum"
            rules={[{ required: true, message: "请输入整改未达标数" }]}
          >
            <InputNumber
              min={1}
              precision={0}
              style={{ width: "100%" }}
              placeholder="请输入整改未达标数"
            />
          </Form.Item>
          <Form.Item label="复测说明" name="retestDesc">
            <Input.TextArea rows={4} placeholder="请输入复测说明" />
          </Form.Item>
          <Form.Item
            label="复测报告"
            hasFeedback
            name="retestReport"
            rules={[
              {
                required: true,
                message: "请上传复测报告",
              },
            ]}
          >
            <div>
              <div className={serviceLess.suffixBox}>
                请上传复测报告，支持.rar .pdf .doc .docx格式，大小不超过50M
              </div>
              <Dgupload
                fileName="复测报告"
                button={true}
                type={[".pdf", ".rar", ".doc", ".docx"]}
                value={retestReport}
                setValue={setRetestReport}
              ></Dgupload>
            </div>
          </Form.Item>
          <Form.Item
            label="是否需整改反馈"
            name="needRectify"
            rules={[{ required: true, message: "请选择是否需整改反馈" }]}
          >
            <Radio.Group defaultValue={true}>
              <Radio value={true}> 是 </Radio>
              <Radio value={false}> 否 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setRetestVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-[150] ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 更改检测报告弹窗 */}
      <Modal
        title="更改检测报告"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setRectifyVisible(false)}
        open={rectifyVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={rectifyForm}
          onFinish={handleSubmitRectify}
        >
          <Form.Item
            label="检测报告"
            hasFeedback
            name="examiningReport"
            rules={[
              {
                required: true,
                message: "请上传检测报告",
              },
            ]}
          >
            <div>
              <div className={serviceLess.suffixBox}>
                请上传检测报告，支持.rar .pdf .doc .docx格式，大小不超过50M
              </div>
              <Dgupload
                fileName="检测报告"
                button={true}
                type={[".pdf", ".rar", ".doc", ".docx"]}
                value={examiningReport}
                setValue={setExaminingReport}
              ></Dgupload>
            </div>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setRectifyVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-[150] ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 新增隐患排查弹窗 */}
      <Modal
        title="新增隐患排查"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setHiddenVisible(false)}
        open={hiddenVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={hiddenForm}
          onFinish={handleSubmitHidden}
        >
          <Form.Item
            label="重大风险数(个)"
            name="majorRiskNum"
            rules={[{ required: true, message: "请输入重大风险数" }]}
          >
            <InputNumber
              min={1}
              precision={0}
              style={{ width: "100%" }}
              placeholder="请输入重大风险数"
            />
          </Form.Item>
          <Form.Item
            label="较大风险数(个)"
            name="higherRiskNum"
            rules={[{ required: true, message: "请输入较大风险数" }]}
          >
            <InputNumber
              min={1}
              precision={0}
              style={{ width: "100%" }}
              placeholder="请输入较大风险数"
            />
          </Form.Item>
          <Form.Item
            label="一般风险数(个)"
            name="normalRiskNum"
            rules={[{ required: true, message: "请输入一般风险数" }]}
          >
            <InputNumber
              min={1}
              precision={0}
              style={{ width: "100%" }}
              placeholder="请输入一般风险数"
            />
          </Form.Item>
          <Form.Item
            label="低风险数(个)"
            name="lowRiskNum"
            rules={[{ required: true, message: "请输入一般风险数" }]}
          >
            <InputNumber
              min={1}
              precision={0}
              style={{ width: "100%" }}
              placeholder="请输入一般风险数"
            />
          </Form.Item>
          <Form.Item label="检测说明" name="examiningDesc">
            <Input.TextArea rows={4} placeholder="请输入检测说明" />
          </Form.Item>
          <Form.Item
            label="检测报告"
            hasFeedback
            name="examiningReport"
            rules={[
              {
                required: true,
                message: "请上传检测报告",
              },
            ]}
          >
            <div>
              <div className={serviceLess.suffixBox}>
                请上传检测报告，支持.rar .pdf .doc .docx格式，大小不超过50M
              </div>
              <Dgupload
                fileName="检测报告"
                button={true}
                type={[".pdf", ".rar", ".doc", ".docx"]}
                value={examiningReport}
                setValue={setExaminingReport}
              ></Dgupload>
            </div>
          </Form.Item>
          <Form.Item
            label="是否需整改反馈"
            name="needRectify"
            rules={[{ required: true, message: "请选择是否需整改反馈" }]}
          >
            <Radio.Group defaultValue={true}>
              <Radio value={true}> 是 </Radio>
              <Radio value={false}> 否 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setHiddenVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-[150] ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 添加/编辑总结情况弹窗 */}
      <Modal
        title="添加/编辑总结情况"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setSummaryVisible(false)}
        open={summaryVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={summaryForm}
          onFinish={handleSubmitSummary}
        >
          <Form.Item
            label="总结情况"
            name="summarize"
            rules={[{ required: true, message: "请输入总结情况" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入总结情况" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setSummaryVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-[150] ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 添加/编辑风险评估报告弹窗 */}
      <Modal
        title="风险评估报告"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setRiskVisible(false)}
        open={riskVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={riskForm}
          onFinish={(values) => handleSubmitOtherRisk(values, "RISK")}
        >
          <Form.Item
            label="报告名称"
            name="name"
            rules={[{ required: true, message: "请输入报告名称" }]}
          >
            <Input placeholder="请输入报告名称" />
          </Form.Item>
          <Form.Item label="说明" name="description">
            <Input.TextArea rows={4} placeholder="请输入说明" />
          </Form.Item>
          <Form.Item
            label="文件"
            hasFeedback
            name="riskFileReport"
            rules={[
              {
                required: true,
                message: "请上传文件",
              },
            ]}
          >
            <div>
              <div className={serviceLess.suffixBox}>
                请上传风险评估报告，支持.rar .pdf .doc .docx格式，大小不超过50M
              </div>
              <Dgupload
                fileName="风险评估报告"
                button={true}
                type={[".pdf", ".rar", ".doc", ".docx"]}
                value={riskFileReport}
                setValue={setRiskFileReport}
              ></Dgupload>
            </div>
          </Form.Item>
          <Form.Item
            label="状态"
            name="enable"
            rules={[{ required: true, message: "请选择状态" }]}
          >
            <Radio.Group>
              <Radio value={true}> 启用 </Radio>
              <Radio value={false}> 禁用 </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setRiskVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-[150] ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 其他文件弹窗 */}
      <Modal
        title="其他文件"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setOtherVisible(false)}
        open={otherVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={otherForm}
          onFinish={(values) => handleSubmitOtherRisk(values, "OTHER")}
        >
          <Form.Item
            label="文件名称"
            name="name"
            rules={[{ required: true, message: "请输入报告名称" }]}
          >
            <Input placeholder="请输入报告名称" />
          </Form.Item>
          <Form.Item label="说明" name="description">
            <Input.TextArea rows={4} placeholder="请输入说明" />
          </Form.Item>
          <Form.Item
            label="文件"
            hasFeedback
            name="otherFileReport"
            rules={[
              {
                required: true,
                message: "请上传文件",
              },
            ]}
          >
            <div>
              <div className={serviceLess.suffixBox}>
                请上传其他文件，支持.rar .pdf .doc .docx格式，大小不超过50M
              </div>
              <Dgupload
                fileName="其他文件"
                button={true}
                type={[".pdf", ".rar", ".doc", ".docx"]}
                value={otherFileReport}
                setValue={setOtherFileReport}
              ></Dgupload>
            </div>
          </Form.Item>
          <Form.Item
            label="状态"
            name="enable"
            rules={[{ required: true, message: "请选择状态" }]}
          >
            <Radio.Group>
              <Radio value={true}> 启用 </Radio>
              <Radio value={false}> 禁用 </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setOtherVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-[150] ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* 服务内容详情弹窗 */}
      <Modal
        centered
        width={700}
        open={serviceVisible}
        footer={false}
        title="服务内容详情"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setServiceVisible(false)}
      >
        <Table
          pagination={false}
          columns={columns3}
          dataSource={serviceContentData}
        />
      </Modal>
    </div>
  );
});
