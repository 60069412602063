import Dgupload from "../../components/dg-upload";
import { Button, Form, Input, InputNumber, Modal, message } from "antd";
import proceedingDetailLess from "./css/proceedingDetail.less";
import { memo, useEffect, useState } from "react";
import Dglist from "../../components/dg-list";
import Dglistitem from "../../components/dg-list-item";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ajax from "../../assets/js/ajax";
import FileList from "./components/fileList";

let navigate: NavigateFunction | null = null;

export default memo(() => {
  navigate = useNavigate();
  const params = useLocation().state as {
    tradeNo: string;
  };

  const [data, setData] = useState({
    tradeNo: "",
    status: -1, //订单状态
    statusName: "", //订单状态
    amount: "", //订单金额
    gmtCreate: "", //创建时间
    payCallbackTime: "", //支付回调时间
    insureSuccessTime: "", //出函成功时间
    payType: "", //支付方式
    payVoucher: "", //支付凭证
    tenderName: "", //投标企业名称
    unifyCreditCode: "", //投标企业的统一社会信用代码
    concatName: "", //联系人
    concatMobile: "", //联系人联系方式
    enterpriseAddress: "", //企业地址
    industryName: "", //行业名称
    safetyLevelName: "", //安全生产标准化等级名称
    insurePerson: "", //投保人数（人）
    insureEffectTimeStart: "", //保函有效开始时间
    insureEffectTimeEnd: "", //保函有效截止时间
    productionAddress: "", //生产经营地址
    cumulativeLimit: "", //累计责任限额(元)
    accidentLimit: "", //每次事故赔偿限额(元)
    perAccidentLimit: "", //每次事故每人责任限额(元)
    perDisabilityLimit: "", //每次事故每人伤残责任限额(元)
    insureOrderNo: "", //保单编号
    financingInstitution: "", //金融机构名称
    auditRemark: "", //审核备注
    projectTypeName: "", // 项目分类
    projectName: "", //项目名称
    realName: "", //项目名称
    mobile: "", //项目名称
    address: "", //项目名称
  });

  const [qualiRankData, setQualiRankData] = useState({
    qualName: "", //企业资质名称
    rankName: "", //级别名称
  });

  const [refForm] = Form.useForm();
  const [unable, setUnable] = useState<boolean>(false);

  const [sentForm] = Form.useForm();
  const [sentUpdateForm] = Form.useForm();
  const [sendVisible, setSendVisible] = useState<boolean>(false);

  const [update, setUpdate] = useState<boolean>(false);
  const [offerForm] = Form.useForm();
  const [offerVisible, setOfferVisible] = useState<boolean>(false);
  const [stuffForm] = Form.useForm();
  const [stuffVisible, setStuffVisible] = useState<boolean>(false);

  const [elePoliceFile, setElePoliceFile] = useState<string>("");
  const [insureFile, setInsureFile] = useState<string>(""); //工程量清单

  const [baohanDocumentList, setBaohanDocumentList] = useState<any[]>([]);
  const [documentGroups, setDocumentGroups] = useState<any[]>([]);

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交材料不足
   * @param {any} values
   * @return {*}
   */
  const handlerSubmitStuff = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/materialSupple",
      data: { tradeNo: params.tradeNo, ...values },
      self: this,
      success: (res) => {
        if (res) {
          setStuffVisible(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交承保报价
   * @return {*}
   */
  const handlerSubmitOffer = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/underwriteAmount",
      data: { tradeNo: params.tradeNo, ...values },
      self: this,
      success: (res) => {
        if (res) {
          setOfferVisible(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交无法承保
   * @param {any} values
   * @return {*}
   */
  const handlerSubmint = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/notCovered",
      data: { tradeNo: params.tradeNo, ...values },
      self: this,
      success: (res) => {
        if (res) {
          setUnable(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 更新出函
   * @param {any} values
   * @return {*}
   */
  const handleSubmitUpdate = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/updateLetterOfGuarantee",
      data: {
        ...values,
        tradeNo: params.tradeNo,
        elePoliceFile: elePoliceFile,
        insureFile: insureFile,
        // certificate: JSON.stringify(certificate2),
      },
      self: this,
      success: (res) => {
        if (res) {
          getDetail();
          setUpdate(false);
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 提交出函
   * @param {any} values
   * @return {*}
   */
  const handleSubmitSend = (values: any) => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/sendALetter",
      data: {
        ...values,
        tradeNo: params.tradeNo,
        elePoliceFile: elePoliceFile,
        insureFile: insureFile,
        // certificate: JSON.stringify(certificate1),
      },
      self: this,
      success: (res) => {
        if (res) {
          setSendVisible(false);
          getDetail();
          message.success("操作成功");
        }
      },
      fail: () => {},
    });
  };

  /**
   * @author: huangtaizhu@nongdaxia.com.cn
   * @description: 打包下载
   * @return {*}
   */
  const handleBatch = () => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/downloadPackage",
      data: {
        tradeNo: params.tradeNo,
      },
      self: this,
      success: (res) => {
        if (res) {
          window.open(res);
        }
      },
      fail: () => {},
    });
  };

  const getDetail = () => {
    ajax.post({
      url: "/openPlatform/engineeringSafeLiability/detail",
      data: params,
      self: this,
      success: (res) => {
        setData({ ...res.order, ...res.enterprise });
        if (res.order.qualiRankData) {
          setQualiRankData(res.order.qualiRankData || {});
        }

        if (res.baohanDocumentList && res.baohanDocumentList.length) {
          setBaohanDocumentList((data) => {
            res.baohanDocumentList.forEach((el: any) => {
              data.push({ ...el });
            });
            return data;
          });
        }
        if (res.documentGroups && res.documentGroups.length) {
          setDocumentGroups((data) => {
            res.documentGroups.forEach((el: any) => {
              data.push({ ...el });
            });
            return data;
          });
        }
      },
      fail: () => {},
    });
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <div>
      <div className="pb-[12]">
        <span className="t-[16] aui-192530 pr-[4]">
          安责险保函订单详情
        </span>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>订单详情</p>
        <div className={proceedingDetailLess.opers}>
          {data.status == 3 && (
            <Button type="primary" onClick={() => setOfferVisible(true)}>
              承保报价
            </Button>
          )}
          {data.status == 3 && (
            <Button type="primary" onClick={() => setStuffVisible(true)}>
              材料不足
            </Button>
          )}
          {data.status == 3 && (
            <Button type="primary" onClick={() => setUnable(true)}>
              无法承保
            </Button>
          )}
          {data.status == 7 && (
            <Button type="primary" onClick={() => setUnable(true)}>
              无法承保
            </Button>
          )}
          {data.status == 7 && (
            <Button type="primary" onClick={() => setSendVisible(true)}>
              出函
            </Button>
          )}
          {data.status == 9 && (
            <Button type="primary" onClick={() => setUpdate(true)}>
              更新出函
            </Button>
          )}
        </div>
        {data.payVoucher && data.payType == "OFFLINE" && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.statusName}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="支付时间">{data.payCallbackTime}</Dglistitem>
            <Dglistitem label="出函时间">{data.insureSuccessTime}</Dglistitem>
            <Dglistitem label="支付凭证">
              <a href={data.payVoucher} target="_blank">
                支付凭证
              </a>
            </Dglistitem>
          </Dglist>
        )}
        {data.payVoucher && data.payType != "OFFLINE" && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.statusName}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="支付时间">{data.payCallbackTime}</Dglistitem>
            <Dglistitem label="出函时间">{data.insureSuccessTime}</Dglistitem>
            <Dglistitem label="支付凭证">
              <span className={proceedingDetailLess.amount__color}>
                {data.payVoucher}
              </span>
            </Dglistitem>
          </Dglist>
        )}
        {!data.payVoucher && (
          <Dglist column={2} labelWidth="200px">
            <Dglistitem label="订单号">{data.tradeNo}</Dglistitem>
            <Dglistitem label="订单状态">{data.statusName}</Dglistitem>
            <Dglistitem label="订单金额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.amount}
              </span>
            </Dglistitem>
            <Dglistitem label="提交时间">{data.gmtCreate}</Dglistitem>
            <Dglistitem label="支付时间">{data.payCallbackTime}</Dglistitem>
            <Dglistitem label="出函时间">{data.insureSuccessTime}</Dglistitem>
          </Dglist>
        )}
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>投保信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="投保企业">{data.tenderName}</Dglistitem>
          <Dglistitem label="统一社会信用代码">
            {data.unifyCreditCode}
          </Dglistitem>
          <Dglistitem label="联系人">{data.realName}</Dglistitem>
          <Dglistitem label="联系人手机号">{data.mobile}</Dglistitem>
          <Dglistitem label="企业地址">{data.address}</Dglistitem>
          <Dglistitem label="企业资质类型与等级">
            {qualiRankData.qualName && qualiRankData.rankName
              ? `${qualiRankData.qualName}-${qualiRankData.rankName}`
              : ""}
          </Dglistitem>
          <Dglistitem label="项目分类">{data.projectTypeName}</Dglistitem>
          <Dglistitem label="安全生产标准化等级">
            {data.safetyLevelName}
          </Dglistitem>
          <Dglistitem label="投保人数（人）">{data.insurePerson}</Dglistitem>
          <Dglistitem label="保函期限">
            {data.insureEffectTimeStart && data.insureEffectTimeEnd
              ? `${data.insureEffectTimeStart}~${data.insureEffectTimeEnd}`
              : ""}
          </Dglistitem>
          <Dglistitem label="生产经营地址">{data.productionAddress}</Dglistitem>
          <Dglistitem label="项目名称">{data.projectName}</Dglistitem>
        </Dglist>
        <div className={proceedingDetailLess.reparation_box}>
          <Dglist column={2} labelWidth="260px">
            <Dglistitem label="累计责任限额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.cumulativeLimit}
              </span>
            </Dglistitem>
            <Dglistitem label="每次事故赔偿限额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.accidentLimit}
              </span>
            </Dglistitem>
            <Dglistitem label="每次事故每人责任限额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.perAccidentLimit}
              </span>
            </Dglistitem>
            <Dglistitem label="每次事故每人伤残责任限额（元）">
              <span className={proceedingDetailLess.amount__color}>
                {data.perDisabilityLimit}
              </span>
            </Dglistitem>
          </Dglist>
        </div>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>保函信息</p>
        <Dglist column={2} labelWidth="200px">
          <Dglistitem label="保函编号">{data.insureOrderNo}</Dglistitem>
          {/* <Dglistitem label="保函品种">{data.guaranteeTypeName}</Dglistitem> */}
          <Dglistitem label="担保机构">{data.financingInstitution}</Dglistitem>
          <Dglistitem label="审核备注">{data.auditRemark}</Dglistitem>
          <div className="w-[100%]">
            {baohanDocumentList.length > 0 &&
              baohanDocumentList.map((item) => {
                return (
                  <div className="w-[100%]" key={item}>
                    <div className={proceedingDetailLess.filesItem}>
                      <div className={proceedingDetailLess.filesLable}>
                        {item.typeName}：
                      </div>
                      <div className={proceedingDetailLess.filesValues}>
                        {item.attachments
                          .sort((a: any, b: any) => {
                            return (
                              new Date(b.time).getTime() -
                              new Date(a.time).getTime()
                            );
                          })
                          .map((curr: any) => {
                            return (
                              <FileList
                                {...curr}
                                files={curr.fileUrls}
                                key={curr}
                              ></FileList>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </Dglist>
      </div>
      <div className={proceedingDetailLess.dg__card}>
        <p className={proceedingDetailLess.title}>附件</p>
        <div className={proceedingDetailLess.opers}>
          <Button type="primary" onClick={handleBatch}>
            打包下载
          </Button>
        </div>
        <div className="w-[100%]">
          {documentGroups.length > 0 &&
            documentGroups.map((item) => {
              return (
                <div className="w-[100%]" key={item}>
                  <div className={proceedingDetailLess.filesItem}>
                    <div className={proceedingDetailLess.filesLable}>
                      {item.typeName}：
                    </div>
                    <div className={proceedingDetailLess.filesValues}>
                      {item.attachments
                        .sort((a: any, b: any) => {
                          return (
                            new Date(b.time).getTime() -
                            new Date(a.time).getTime()
                          );
                        })
                        .map((curr: any) => {
                          return (
                            <FileList
                              {...curr}
                              files={curr.fileUrls}
                              key={curr}
                            ></FileList>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="text-center mt-[32]">
        <Button
          type="default"
          className="w-160"
          onClick={() => {
            navigate!("/admin/safety");
          }}
        >
          返回
        </Button>
      </div>

      <Modal
        title="承保报价"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setOfferVisible(false)}
        open={offerVisible}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={offerForm}
          onFinish={handlerSubmitOffer}
        >
          <Form.Item
            label="报价"
            name="amount"
            rules={[{ required: true, message: "请输入报价" }]}
          >
            {/* <Input placeholder="请输入报价" /> */}
            {/* step={0.01} */}
            <InputNumber
              min="0"
              step="0.01"
              stringMode={true}
              style={{ width: "100%" }}
              formatter={(value: any) => {
                //指定输入框展示值的格式
                const reg1 = `${value}`.replace(
                  /^(\-)*(\d+)\.(\d\d).*$/,
                  "$1$2.$3"
                );
                return reg1;
                // return reg1.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                //如果不需要添加千位分隔符可以直接返回return reg1
              }}
              controls={false}
            />
          </Form.Item>
          <Form.Item
            label="审核备注"
            name="auditRemark"
            rules={[{ required: false, message: "请输入审核备注" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setOfferVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="材料不足"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setStuffVisible(false)}
        open={stuffVisible}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={stuffForm}
          onFinish={handlerSubmitStuff}
        >
          <Form.Item
            label="审核备注"
            name="auditRemark"
            rules={[{ required: true, message: "请输入审核备注" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setStuffVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="无法承保"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setUnable(false)}
        open={unable}
        footer={false}
        centered
        width={500}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={refForm}
          onFinish={handlerSubmint}
        >
          <Form.Item
            label="审核备注"
            name="auditRemark"
            rules={[{ required: true, message: "请输入审核备注" }]}
          >
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setUnable(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="出函"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setSendVisible(false)}
        open={sendVisible}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={sentForm}
          onFinish={handleSubmitSend}
        >
          <Form.Item
            label="保函编号"
            name="insureOrderNo"
            rules={[{ required: true, message: "请输入保函编号" }]}
          >
            <Input placeholder="请输入保函编号" />
          </Form.Item>
          <Form.Item
            label="保函保单文件"
            hasFeedback
            name="elePoliceFile"
            rules={[
              {
                required: true,
                message: "请上传保函保单文件",
              },
            ]}
          >
            <div>
              <Dgupload
                fileName="保函保单文件"
                button={true}
                type={[".pdf"]}
                value={elePoliceFile}
                setValue={setElePoliceFile}
              ></Dgupload>
              <div className={proceedingDetailLess.suffixBox}>
                支持拓展名：.PDF
              </div>
            </div>
          </Form.Item>
          <Form.Item label="保函凭证文件" name="insureFile">
            <Dgupload
              fileName="保函凭证文件"
              button={true}
              type={[".pdf"]}
              value={insureFile}
              setValue={setInsureFile}
            ></Dgupload>
            <div className={proceedingDetailLess.suffixBox}>
              支持拓展名：.PDF
            </div>
          </Form.Item>
          <Form.Item label="审核备注" name="auditRemark">
            <Input.TextArea rows={4} placeholder="请输入审核备注" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setSendVisible(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="更新保函"
        forceRender={true}
        maskClosable={true}
        onCancel={() => setUpdate(false)}
        open={update}
        footer={false}
        centered
        width={700}
      >
        <Form
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={sentUpdateForm}
          onFinish={handleSubmitUpdate}
        >
          <Form.Item
            label="保函保单文件"
            name="elePoliceFile"
            rules={[{ required: true, message: "请上传保函保单文件" }]}
          >
            <Dgupload
              fileName="保函保单文件"
              type={[".pdf"]}
              setValue={setElePoliceFile}
              value={elePoliceFile}
              button={true}
            ></Dgupload>
          </Form.Item>
          <Form.Item label="保函凭证文件" name="insureFile">
            <Dgupload
              fileName="保函凭证文件"
              type={[".pdf"]}
              setValue={setInsureFile}
              value={insureFile}
              button={true}
            ></Dgupload>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="default"
              className="w-150"
              onClick={() => setUpdate(false)}
            >
              取消
            </Button>
            <Button
              type="primary"
              className="w-150 ml-[20]"
              htmlType="submit"
            >
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
});
